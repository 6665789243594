import {ConfigProvider} from "antd";
import {StrictMode, useState} from "react";
import {Provider, useSelector} from "react-redux";

import FingerprintJS from "@fingerprintjs/fingerprintjs";
import {userAPI} from "~/api/userAPI";
import {navigationAPI} from "~/api/navigationAPI";
import {classPrefix, useOnMountUnsafe} from "~/lib";

import {getAppStatus, init as appInit} from "~/reducers/app";
import {getAccountStatus, init as accountInit} from "~/reducers/account";
import {getNavigationStatus, init as navigationInit} from "~/reducers/navigation";

import store, {useAppDispatch} from "~/store";

import config from "~/config";

import "reset-css";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import "@fontsource-variable/roboto-mono/wght.css";
import "@fontsource/ubuntu/300.css";
import "@fontsource/ubuntu/400.css";
import "@fontsource/ubuntu/500.css";
import "@fontsource/ubuntu/700.css";
import "@fontsource/ubuntu-mono/400.css";
import "@fontsource/ubuntu-mono/700.css";
import "~/main.css";
import "~/variables.css";
import Router from "~/components/features/Router";

function AppWrapper() {
    const dispatch = useAppDispatch();
    const [initiating, setInitiating] = useState({});
    const statuses = {
        app: useSelector(getAppStatus),
        account: useSelector(getAccountStatus),
        navigation: useSelector(getNavigationStatus),
    };
    useOnMountUnsafe(
        () => {
            // noinspection JSUnusedGlobalSymbols
            const initChain = {
                app: async () => {
                    const fp = await FingerprintJS.load();
                    const fingerprint = await fp.get();
                    dispatch(appInit({fingerprint}));
                },
                account: async () => {
                    dispatch(accountInit());
                    const userAPIPromise = dispatch(userAPI.endpoints.getUserByUserId.initiate("current"));
                    // noinspection JSUnresolvedReference
                    userAPIPromise.unsubscribe();
                    await userAPIPromise;
                },
                navigation: async () => {
                    dispatch(navigationInit());
                    const navigationAPIPromise = dispatch(navigationAPI.endpoints.getNavigationMenus.initiate());
                    // noinspection JSUnresolvedReference
                    navigationAPIPromise.unsubscribe();
                    await navigationAPIPromise;
                },
            };
            Object.keys(initChain).forEach(key => {
                const callback = initChain[key];
                if (!statuses[key] && !initiating[key]) {
                    setInitiating({
                        ...initiating,
                        [key]: true
                    });
                    callback();
                }
            });
        },
        []
    );

    return <Router pages={config.routes}/>;
}

function App() {
    return <StrictMode>
        <Provider store={store}>
            <ConfigProvider theme={{cssVar: {prefix: "ant"}}} prefixCls={classPrefix("ant")}>
                <AppWrapper />
            </ConfigProvider>
        </Provider>
    </StrictMode>;
}

export default App;
