import {
    createBrowserRouter,
    RouterProvider as ReactRouterProvider,
    useInRouterContext,
    useRoutes
} from "react-router-dom";
import {prepareRoutes} from "~/lib";
import {RouterProps} from "~/@types/components/features/RouterProps";
import ErrorBoundary from "~/components/common/ErrorBoundary";
import Route from "~/components/features/Route";
import Wrapper from "~/components/templates/Wrapper";
import {ReactElement} from "react";

export default function Router({
    pages,
    basename = APP_BASE_PATH ? APP_BASE_PATH : "/",
    renderFunction = (slug, page) => <ErrorBoundary>
        <Route Component={Wrapper} slug={slug} page={page}/>
    </ErrorBoundary>,
    prefixSlug = "",
    prefixPath = ""
}: RouterProps){
    let ret: ReactElement | null;
    const routes = prepareRoutes(
        pages,
        renderFunction,
        prefixSlug,
        prefixPath
    );
    if (useInRouterContext()) {
        ret = useRoutes(routes);
    } else {
        const router = createBrowserRouter(
            routes,
            {basename}
        );
        ret = <ReactRouterProvider router={router}/>;
    }

    return ret;
}